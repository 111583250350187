/* For small common helper utility functions */
import { v4 as uuidv4 } from "uuid";

export const titleCase = (str) => {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
};

export const findIndexInObjArray = (myArray, searchTerm, property) => {
  for (var i = 0, len = myArray.length; i < len; i++) {
    if (myArray[i][property] === searchTerm) return i;
  }
  return -1;
};

export function objectToQueryString(obj) {
  return Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join("&");
}

export const getRandomNumber = (start, end) => {
  return Math.floor(Math.random() * end) + start;
};

export function capitalizeFirstLetter(word) {
  if (typeof word !== "string") return "";
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function sortArrOfObjects(arr, param, type) {
  if (type === "desc")
    return arr.sort((a, b) =>
      a[param] > b[param] ? -1 : b[param] > a[param] ? 1 : 0
    );
  else if (type === "asc")
    return arr.sort((a, b) =>
      a[param] > b[param] ? 1 : b[param] > a[param] ? -1 : 0
    );
}

export function arrayRandomShuffle(arr) {
  return arr
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
}

export function splitArrChunks(arr, noOfChunks) {
  if (!arr || !noOfChunks) throw new Error("Params missing");

  return arr.reduce((accum, curr, index, array) => {
    if (index % noOfChunks === 0)
      accum.push(array.slice(index, index + noOfChunks));
    return accum;
  }, []);
}

export function convertArrToObj(arr, key) {
  const obj = {};
  arr.forEach((item) => {
    obj[item[key]] = item;
  });
  return obj;
}

export function formatBullets(sentence, Fontstyle) {
  if (!sentence) return "";
  const items = sentence.split("|").map((item, index) => (
    <li key={index} className="mt-2 text-justify" style={Fontstyle}>
      {item.trim()}
    </li>
  ));
  return <ul className="pl-5 break-words text-xs m-0">{items}</ul>;
}

export function getAuthToken() {
  return localStorage.getItem("authToken");
}

export function isLoggedIn() {
  if (getAuthToken()) return true;
  return false;
}

export function handleLogout() {
  localStorage.clear();
  window.location.href = "/";
}
export function handleLogoutChannelPartner() {
  localStorage.clear();
  window.location.href = "/channelpartner";
}

export function stateUpdater(setState) {
  return (key, value) => {
    setState((prev) => ({ ...prev, [key]: value }));
  };
}

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    if (!(file instanceof Blob)) {
      reject(new Error("Provided file is not a Blob or File object"));
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export function trimExtraSpaces(str) {
  if (typeof str === "string") {
    return str.replace(/\s+/g, " ").trim();
  } else {
    return "";
  }
}
export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
export function convertURIToImageData(URI) {
  return new Promise(function (resolve, reject) {
    if (URI == null) return reject();
    var canvas = document.createElement("canvas"),
      context = canvas.getContext("2d"),
      image = new Image();
    image.addEventListener(
      "load",
      function () {
        canvas.width = image.width;
        canvas.height = image.height;
        context.drawImage(image, 0, 0, canvas.width, canvas.height);
        resolve(context.getImageData(0, 0, canvas.width, canvas.height));
      },
      false
    );
    image.src = URI;
  });
}

export function dataURItoBlob(dataURI) {
  const byteString = atob(dataURI.split(",")[1]);
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
}

export function extractUserName(url) {
  const linkedInRegex = /linkedin\.com\/in\/([^\/]+)\/?$/;
  const match = url.match(linkedInRegex);
  return match ? match[1] : null;
}

export function capitalizeWords(sentence) {
  const excludeWords = [
    "i",
    "me",
    "my",
    "myself",
    "we",
    "us",
    "our",
    "ourselves",
    "you",
    "your",
    "he",
    "him",
    "his",
    "himself",
    "she",
    "her",
    "hers",
    "herself",
    "it",
    "its",
    "itself",
    "they",
    "them",
    "their",
    "theirs",
    "themselves",
    "a",
    "an",
    "and",
    "as",
    "at",
    "but",
    "by",
    "for",
    "in",
    "nor",
    "of",
    "on",
    "or",
    "so",
    "the",
    "to",
    "up",
    "yet",
  ];

  function capitalize(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  const words = sentence.toLowerCase().split(" ");

  const capitalizedSentence = words
    .map((word, index) => {
      if (index === 0) {
        return capitalize(word);
      }

      if (!excludeWords.includes(word)) {
        return capitalize(word);
      }
      return word;
    })
    .join(" ");

  return capitalizedSentence;
}

function isEmpty(value) {
  if (value === null || value === undefined) return true;
  if (typeof value === "string" && value.trim() === "") return true;
  if (typeof value === "number" && value === 0) return true;
  if (Array.isArray(value) && value.length === 0) return true;
  if (
    typeof value === "object" &&
    !Array.isArray(value) &&
    Object.keys(value).length === 0
  )
    return true;
  return false;
}

export function areAllValuesEmpty(obj) {
  return Object.values(obj).every(isEmpty);
}

export function screen(key, expandState) {
  const width = `${key}`;
  let obj = {};
  switch (width) {
    case "646":
      obj = {
        transform: expandState ? "scale(0.7)" : "scale(0.55)",
        marginTop: expandState ? "-10rem" : "-14rem",
        expandBtn: "absolute top-[687px] right-[80px]",
        marginLeft: "",
      };
      return obj;
    case "752":
      obj = {
        transform: expandState ? "scale(0.7)" : "scale(0.6)",
        marginTop: "20rem",
        expandBtn: "absolute top-[1249px] right-[105px]",
        marginLeft: "-4rem",
      };
      return obj;
    case "746":
      obj = {
        transform: expandState ? "scale(0.7)" : "scale(0.6)",
        marginTop: "18rem",
        expandBtn: "absolute top-[1249px] right-[105px]",
        marginLeft: "-4rem",
      };
      return obj;
    default:
      obj = {
        transform: expandState ? "scale(0.7)" : "scale(0.45)",
        marginTop: expandState ? "-10rem" : "-18rem",
        expandBtn: "absolute top-[560px] right-[170px]",
        marginLeft: "",
      };
      return obj;
  }
}

export const handleToggleFxn = async (option, toggleFxn, ref) => {
  await toggleFxn();
  if (option === "hidden") {
    ref.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  ref.current.style.overflow = option;
};
const isLeapYear = (year) => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

export const calculateDays = (years) => {
  let totalDays = 0;
  let currentYear = new Date().getFullYear();

  for (let i = 0; i < years; i++) {
    if (isLeapYear(currentYear + i)) {
      totalDays += 366;
    } else {
      totalDays += 365;
    }
  }
  return totalDays;
};

export const generateShortId = () => {
  const fullUuid = uuidv4();
  const id = fullUuid.split("-")[0];
  return id;
};
