import { Spin } from "antd";
import React from "react";

export default function Preloader() {
  return (
    <div className="min-h-[90vh] min-w-[97vw] flex items-center justify-center">
      <Spin spinning={true}></Spin>
    </div>
  );
}
