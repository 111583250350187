import { postRequest } from "./_setup";

export function postLoginGoogle(body) {
  return postRequest(`/auth/login/google`, body);
}

export function postRefreshToken(body) {
  return postRequest(`/auth/token/refresh`, body);
}

export function postLoginGoogleChannelPartner(body) {
  return postRequest(`/auth/login/channelpartner`, body);
}
