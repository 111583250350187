import React, { useEffect } from "react";
import style from "./Footer.module.scss";
import clsx from "clsx";
import Logo from "components-shared/Logo";
import { NavLink } from "react-router-dom";
import { routes } from "index/routes";

const Links = [
  {
    title: "Products",
    links: [
      { title: "Resume", link: "/templates" },
      {
        title: "Interview tools",
        link: routes.Prep,
        // isExternal: true
      },
    ],
  },
  {
    title: "Support",
    links: [
      { title: "Help", link: "/faq" },
      { title: "Contact us", link: "/contact-us" },
      { title: "Terms And Conditions", link: "/terms-and-conditions" },
    ],
  },
  {
    title: "Legal",
    links: [
      { title: "Privacy", link: routes.PrivacyPolicy },
      { title: "Cookie Policy", link: routes.CookiePolicy },
      { title: "Refund Policy", link: routes.RefundPolicy },
      { title: "Pricing Policy", link: routes.PricingPolicy },
      { title: "Shipping Policy", link: routes.ShippingPolicy },
    ],
  },
];

const scrollTop = () => {
  window.scrollTo(0, 0);
};

export default function Footer() {
  return (
    <div className="bg-[#2846bd] py-5 mt-32">
      <div
        className={clsx(
          style.wrapper,
          "withPadding flex flex-col md:items-center gap-4 md:flex-row items-start"
        )}
      >
        <div
          className={clsx("!min-w-[220px] flex items-start justify-start ml-2")}
          onClick={scrollTop}
        >
          <Logo theme="dark" w="210" />
        </div>

        <div
          className={clsx(
            style.links,
            "flex flex-col md:flex-row md:gap-16  gap-10 items-center justify-center"
          )}
        >
          {Links.map((item) => (
            <div className={clsx(style.linksCol, "gap-3")} key={item.title}>
              <span className={style.title}>{item.title}</span>
              <nav className="gap-3">
                {item.links.map((link) => (
                  <span className="whitespace-nowrap" key={link.title}>
                    {
                      <NavLink
                        to={link.link}
                        className={clsx("text-[#B9B9B9] text-left")}
                      >
                        {link.title}
                      </NavLink>
                    }
                  </span>
                ))}
              </nav>
            </div>
          ))}
          <div
            className={clsx(
              style.copyright,
              "flex flex-col gap-3 min-w-[226px]"
            )}
          >
            <span className="text-[#fff] font-semibold ">Contact</span>
            <a
              href="mailto:support@razorhire.ai"
              className="text-[#B9B9B9] block mt-4 md:mt-0"
            >
              support@razorhire.ai
            </a>

            <a className="text-[#B9B9B9] block">
              101, Level 1, Elemental #337, Road No 1, Malakunta, Financial
              District, Hyderabad, Telangana, 500032
            </a>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="border  border-[#B9B9B9] border-solid my-8 md:min-w-[76%] min-w-[65%] mx-10"></div>
      </div>
      <span className="grid place-items-center mb-0 ">
        <a
          href="/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
          className="text-[#B9B9B9]"
        >
          Copyright &copy; RazorHire 2024
        </a>
      </span>
    </div>
  );
}
