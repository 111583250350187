import clsx from "clsx";
import { Input as AntInput } from "antd";

export default function Input({
  label,
  className,
  validationErrors,
  classForValidation,
  ...props
}) {
  return (
    <div
      className={`${className} relative border border-[#ccc] rounded-md h-10 border-solid`}
      aria-disabled={props.disabled}
    >
      <label
        htmlFor={props.name}
        className="block absolute text-[0.8rem] top-0 -mt-2 left-2 px-2 bg-[#fff]"
      >
        {label}
      </label>
      <input
        id={props.name}
        spellCheck="true"
        className="border-none outline-none mt-3 pl-4 w-full pr-4 bg-transparent text-[0.8rem]"
        {...props}
      />
      {validationErrors && validationErrors[props.name] && (
        <p className={clsx(classForValidation, "text-xs text-red-700 pl-4")}>
          {validationErrors[props.name].message}
        </p>
      )}
    </div>
  );
}
