export const lazyRetry = (componentImport) =>
  new Promise((resolve, reject) => {
    const storageKey = `retry-lazy-refreshed${btoa(
      componentImport.toString()
    )}`;
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(storageKey) || "false"
    );
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(storageKey, "false");
        if (component === undefined) {
          window.sessionStorage.setItem(storageKey, "true");
          return window.location.reload();
        }
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          window.sessionStorage.setItem(storageKey, "true");
          window.location.reload();
        }
        reject(error);
      });
  });
