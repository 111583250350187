import React from "react";

import LogoImg from "assets/images/logo.svg";
import LogoWhiteImg from "assets/images/razorhire-ccc-blue-logo.svg";
import { useNavigate } from "react-router-dom";

export default function Logo({ theme = "dark", w = "150" }) {
  const navigate = useNavigate();
  const src = theme.toLowerCase() === "light" ? LogoImg : LogoWhiteImg;

  return (
    <img
      style={{ width: `${w}px` }}
      src={src}
      alt="logo"
      onClick={() => navigate("/")}
      className="cursor-pointer"
    />
  );
}
