import { Navigate } from "react-router-dom";
import { isLoggedIn } from "utils/helpers";
import { useAppContext } from "../contexts/AppContext";

export function useWithAuth() {
  function noAuthRedirect() {
    const userRole = localStorage.getItem("role");
    const route = userRole === "channelpartner" ? "/channelpartner" : "/";
    return <Navigate to={route} />;
  }
  function authorize(comp, roles, redirect) {
    if (!Array.isArray(roles)) roles = [roles];

    if (!isLoggedIn()) return noAuthRedirect();

    const userRole = localStorage.getItem("role");
    if (
      roles.some((role) => role === userRole) &&
      userRole !== "channelpartner"
    )
      return comp;
    else return noAuthRedirect();
  }

  return { authorize };
}

export function useWithAuthChannelPartner() {
  function noAuthRedirect() {
    const userRole = localStorage.getItem("role");
    const route = userRole === "student" ? "/" : "/channelpartner";
    return <Navigate to={route} />;
  }
  function authorizeChannelPartner(comp, roles, redirect) {
    if (!Array.isArray(roles)) roles = [roles];

    if (!isLoggedIn()) return noAuthRedirect();

    const userRole = localStorage.getItem("role");

    if (roles.some((role) => role === userRole) && userRole !== "student") {
      return comp;
    } else return noAuthRedirect();
  }

  return { authorizeChannelPartner };
}

// export function useWithAuthChannelPartnerRegistration() {
//   function noAuthRedirect() {
//     return <Navigate to="/channelpartner" />;
//   }
//   function authorizeChannelPartnerRegistrion(comp, roles, redirect) {
//     if (!Array.isArray(roles)) roles = [roles];

//     if (!isLoggedIn()) return noAuthRedirect();
//     const userRole = localStorage.getItem("role");
//     if (roles.some((role) => role === userRole)) return comp;
//   }

//   return { authorizeChannelPartnerRegistrion };
// }
