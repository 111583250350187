import React from "react";

import clsx from "clsx";
import Modal from "components-shared/Modal/Modal";
import Button from "components-shared/Button/Button";
import Input from "components-shared/Form/Input/Input";

import registrationImg from "assets/images/registration.svg";

import { useAppContext } from "contexts/AppContext";
import { AiOutlineLogout as LogOut } from "react-icons/ai";

import { IoIosInformationCircleOutline } from "react-icons/io";

import { Checkbox } from "antd";
import { Modal as AntModal } from "antd";

export default function Registration({
  isOpen,
  onCancel,
  state,
  onChange,
  handleEmailUpdate,
}) {
  const { state: appstate } = useAppContext();
  const { confirm } = AntModal;

  const showConfirm = () => {
    confirm({
      title: "This Will Log you out!!",
      icon: <IoIosInformationCircleOutline size={24} />,
      content: "Do you want to log out ?",
      onOk() {
        onCancel();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <Modal
      title="Welcome to RazorHire"
      open={isOpen}
      width={700}
      footer={null}
      onCancel={showConfirm}
      maskClosable={false}
      closable={true}
      closeIcon={<LogOut />}
    >
      <div className={clsx("flex justify-center items-center pt-4 pb-8")}>
        <div className="max-w-[70%]">
          <h5 className={clsx("m-0 text-[1rem]  text-center")}>
            Enter your Professional Email Address
          </h5>
          <p className={clsx("m-0 mb-8 text-center")}>
            This will be displayed on your resume and cannot be changed later.
          </p>
          <div className="flex justify-center">
            <Input
              label="Email Address *"
              className="mb-4 min-w-80"
              placeholder="abcd@gmail.com"
              name="professionalEmail"
              value={state.oldEmail ? appstate.userProfile.email : state.email}
              onChange={(e) => onChange("email", e)}
              disabled={state.oldEmail}
              validationErrors={state.errors}
            />
          </div>
          <Checkbox
            name="email"
            checked={state.oldEmail}
            onChange={(e) => onChange("checkbox", e)}
          >
            Professional email address is same as Google login email address
          </Checkbox>
          <div className={clsx("flex justify-center")}>
            <Button
              type="primary"
              className={clsx("mt-8 px-6")}
              size="large"
              onClick={handleEmailUpdate}
              shape="round"
              paddingInline={"25px"}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
